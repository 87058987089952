<template>
  <div
      :class="classesString"
      :style="stylesString"
      class="content-swiper-stack-cmp"
      @touchstart.stop.prevent="touchStart"
      @touchmove.stop.prevent="touchMove"
      @touchend.stop.prevent="touchEnd"
  >
    <TransitionGroup name="card">
      <div v-for="(item, idx) in visibleItems" :key="item.id" class="content-swiper-stack-cmp__card" :style="cardStyle(idx)">
        <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorage(item.data, item.id))">
          <atu-components-renderer :items="block.children"/>
        </data-provider>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {renderMixins} from "../../renderMixins";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";
import {reactive} from 'vue';

export default {
  components: {DataProvider, AtuComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "ContentSwiperStackEditorCmp",

  data() {
    return {
      currentIndex: 0,
      touchStartX: 0,
      touchMoveX: 0
    };
  },

  computed: {
    AbStorage() {
      return AbStorage
    },

    /**
     * Return items list
     * @returns {*|*[]}
     */
    items() {
      // Get items list
      const itms = this.getValue(this.block?.properties?.dataSource) || []
      const items = (Array.isArray(itms) ? itms : []).map(el => {
        return typeof el === 'object' ? el : {value: el}
      })

      // Show log message
      this.renderer.getA2U().log(`Repeater items: `, items)

      // Return items
      return items.map((data, idx) => ({
        id: idx + 1,
        data,
      }))
    },

    // Return visible items
    visibleItems() {
      // Get total items
      const totalItems = this.items.length;
      // Get start and end indexes
      const start = this.currentIndex;
      const end = (this.currentIndex + 3) % totalItems;

      let result = [];

      // Get visible items
      if (start < end) {
        result = this.items.slice(start, end);
      } else {
        result = this.items.slice(start).concat(this.items.slice(0, end));
      }

      // Return visible items
      return result.reverse();
    },

    // Return total items
    totalItems() {
      return this.items.length
    },
  },

  methods: {
    /**
     * Prepare storage
     * @param item
     * @param k
     * @return {{initialStorage: {item: {number: *}}}}
     */
    prepareStorage(item, k) {
      // check if children can change the datasource data
      const isWritable = this.block?.properties?.dataIsWritable
      const storageCreator = (data) => reactive(data);

      // return initial storage
      return !isWritable ?
          {initialStorage: {item: Object.assign({number: k+1}, item)}, storageCreator} :
          {initialStorage: {number: k+1}, storage: {item: Object.assign(item)}, storageCreator}
    },

    /**
     * Returns the style for a card based on its index.
     * The style includes transformations and z-index adjustments.
     *
     * @param {number} index - The index of the card.
     * @returns {Object} The style object for the card.
     */
    cardStyle(index) {
      const reversedIndex = this.visibleItems.length - 1 - index;
      if (reversedIndex === 0) {
        const rotation = this.touchMoveX / 10;
        const translateX = this.touchMoveX;
        return {
          transform: `translateX(${translateX}px) rotate(${rotation}deg)`,
          transition: this.touchMoveX ? 'none' : 'transform 0.3s',
          zIndex: 3
        };
      }
      return {
        transform: `scale(${1 - reversedIndex * 0.05}) translateY(${-reversedIndex * 30}px)`,
        opacity: 1 - reversedIndex * 0.3,
        zIndex: 2 - reversedIndex
      };
    },

    /**
     * Handles the touch start event.
     * Sets the initial touch position.
     *
     * @param {TouchEvent} event - The touch start event.
     */
    touchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },

    /**
     * Handles the touch move event.
     * Updates the touch move position.
     *
     * @param {TouchEvent} event - The touch move event.
     */
    touchMove(event) {
      this.touchMoveX = event.touches[0].clientX - this.touchStartX;
    },

    /**
     * Handles the touch end event.
     * Moves to the next item if the touch move distance is greater than 100 pixels.
     * Resets the touch move position.
     */
    touchEnd() {
      if (Math.abs(this.touchMoveX) > 100) {
        this.moveToNext();
      }
      this.touchMoveX = 0;
    },

    /**
     * Moves to the next item in the list.
     * Resets the current index if it exceeds the length of the items list.
     * Processes the selected item.
     */
    moveToNext() {
      this.currentIndex++;

      if (this.currentIndex >= this.items.length) {
        this.currentIndex = 0;
      }

      this.processSelectedItem();
    },

    /**
     * Processes the selected item.
     * Sets the operation value for the selected item and processes outgoing links.
     */
    processSelectedItem() {
      const item = this.items[this.currentIndex]?.data || null;

      const varToSaveSelectedItem = this.block.properties?.varToSaveSelectedItem;

      if (varToSaveSelectedItem) {
        this.setOperationValue(varToSaveSelectedItem, 'set', {
          valueType: 'static',
          value: item,
        });
      }

      this.parentDiagram.processOutgoingLinks(this, this.block.id, item, 'selected');
    },
  },

  watch: {
    totalItems() {
      this.currentIndex = 0;
      this.processSelectedItem();
    }
  },

  mounted() {
    // Process selected item
    this.$watch('items', () => {
      this.processSelectedItem();
    }, {immediate: true});

  },
}

</script>

<style lang="scss">

.content-swiper-stack-cmp {
  position: relative;
  width: 100%;
  height: 100%;

  &__card {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 60px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:not(:last-child) {
      transform-origin: top center;
    }
  }
}

</style>
