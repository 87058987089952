import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class PdfGeneratorProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     */
    async processEvent() {
        const syncResponse = this.block?.properties?.syncResponse === 1;

        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                ...(syncResponse ? {'job-id': '1'} : {data: {}}),
                error: {message: 'Error calling tool documents:pdf-generator'},
            });
        }

        const styles = `
          .dg-text-weight-thin {
            font-weight: 100;
          }
        
          .dg-text-weight-regular {
            font-weight: 400;
          }
        
          .dg-text-weight-bold {
            font-weight: 700;
          }
          ${this.context.renderer.a2u.getHtmlStyles()}
        `;

        const sourceHtml = this.context.interpretString(this.block?.properties?.html);
        const html = sourceHtml ? `
            <html lang="${this.context.renderer.currentLanguage || 'en'}">
                <head><style>${styles}</style></head>
                <body>${sourceHtml}</body>
            </html>
        ` : null;

        if (!html) {
            this._debugLog('Missing properties: html');

            return;
        }

        try {
            // Call the tool
            const result = await this.context?.renderer?.a2u?.callTool('documents:pdf-generator', {
                html,
            }, { sync: syncResponse });

            // Process the result
            if (syncResponse) {
                this.processOutgoingLinks(this.context, this.block.id, result?.url, 'pdf-url');

                const varToSavePdfUrl = this.block.properties?.varToSavePdfUrl;

                if (varToSavePdfUrl) {
                    this.context.setOperationValue(varToSavePdfUrl, 'set', {
                        valueType: 'static',
                        value: result?.url,
                    });
                }
            } else {
                // Process the result
                if (!result?.jobId) {
                    throw 'Job ID not found';
                }

                const varToSaveJobId = this.block.properties?.varToSaveJobId;

                if (varToSaveJobId) {
                    this.context.setOperationValue(varToSaveJobId, 'set', {
                        valueType: 'static',
                        value: result.jobId,
                    });
                }

                this.processOutgoingLinks(this.context, this.block.id, result.jobId, 'job-id');
            }
        } catch (e) {
            console.error('Error calling tool documents:pdf-generator', e);

            this._debugLog('Error calling tool documents:pdf-generator');

            this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }

    /**
     * Logs a debug message with detailed context information.
     * This method logs an error message along with detailed context information including the process number,
     * diagram component ID, block ID, and patched component properties. The log entry is created using the
     * `debugLogger` of the `a2u` renderer.
     *
     * @param {string} message - The debug message to log.
     */
    _debugLog(message) {
        this.context.renderer.a2u.debugLogger.log({
            type: 'error',
            message,
            data: JSON.parse(JSON.stringify({
                processNum: this.processNum,
                diagram: { id: this.context?.currentDiagram?.block?.properties?.diagramComponentId },
                component: {
                    id: this.block?.id,
                    properties: this.context.renderer.a2u.patchComponentProperties(this, this.block?.properties || {}),
                },
                data: {},
            })),
        });
    }
}
