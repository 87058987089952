<template>

  <ab-flow-base-cmp class="rating-cmp dg-justify-center" :block="block" :class="classesString" :style="stylesString">
    <q-rating
      v-model="currentValue"
      color="grey"
      :size="iconSize"
      color-selected="warning"
      @update:modelValue="rated"
      v-bind="dynamicProps"
    />
  </ab-flow-base-cmp>

</template>

<script>
import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "../../Form/formComponentsMixins";
import {formDefaultValueMixin} from "../../Form/formDefaultValueMixin";
import {constants} from "../../../constants";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins, formDefaultValueMixin],
  props: ['block'],
  name: "RatingEditorCmp",
  data: () => ({
  }),
  created() {
    if(this.currentValue === undefined) this.currentValue = 0;
  },
  computed: {
    iconSize() {
      const sz = this.block?.properties?.iconSize || "sm";
      return 2*constants.sizes_to_pixels[sz] + 'px'
    }
  },
  methods: {
    rated() {
      this.parentDiagram.processOutgoingLinks(this, this.block.id, this.currentValue, "rated")
    }
  }
}

</script>
