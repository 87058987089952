<template>
  <div class="chat-media-attachment">

    <q-dialog
        v-model="showFullscreen"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
        class="chat-media-preview"
    >
      <div class="column bg-black">
        <div class="absolute-right dg-mt-sa q-mr-lg">
          <q-btn icon="close" outline round class="bg-black text-white q-mt-lg" @click="showFullscreen = false" />
        </div>
        <img class="col" :src="mediaUrl" />
      </div>
    </q-dialog>

    <template v-if="type === 'image'">
      <img :src="mediaUrl" alt="image" @click="showFullscreen=true;"/>
    </template>
    <template v-else>
      Not supported media type: {{ type }}
    </template>

    <template v-if="title">
      <q-separator class="q-mt-sm q-mb-sm"/>
      <div class="title" v-html="title"/>
    </template>

  </div>
</template>

<script>

export default {
  name: "ChatMediaAttachment",
  props: ['type', 'mediaUrl', 'title'],
  data: () => ({
    showFullscreen: false
  })
}

</script>

<style lang="scss">

.chat-media-preview {
  img {
    width: 100%;
    object-fit: contain;
  }
}

.chat-media-attachment {
  .title {
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
