<template>
  <div :class="classesString" :style="stylesString" class="repeater-cmp">
    <q-virtual-scroll
      v-if="virtualScroll"
      :items="items"
      :style="{maxHeight: virtualScrollMaxHeight}"
      :virtual-scroll-item-size="virtualScrollItemSize"
      :virtual-scroll-slice-size="virtualScrollSliceSize"
      :virtual-scroll-slice-ratio-before="virtualScrollSliceRatioAfter"
      :virtual-scroll-slice-ratio-after="virtualScrollSliceRatioBefore"
      :virtual-scroll-horizontal="virtualScrollHorizontal"
      v-slot="{ item, index }"
    >
      <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorage(item, index))">
        <atu-components-renderer :items="block.children"/>
      </data-provider>
    </q-virtual-scroll>

    <template v-else>
      <template v-for="(item, k) of items" :key="k">
        <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorage(item, k))">
          <atu-components-renderer :items="block.children"/>
        </data-provider>
      </template>
    </template>
  </div>

</template>

<script>
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {renderMixins} from "../../renderMixins";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";
import {reactive} from 'vue';

export default {
  components: {DataProvider, AtuComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "ListViewEditorCmp",
  methods: {

    /**
     * Prepare storage
     * @param item
     * @param k
     * @return {{initialStorage: {item: {number: *}}}}
     */
    prepareStorage(item, k) {
      // check if children can change the datasource data
      const isWritable = this.block?.properties?.dataIsWritable
      const storageCreator = (data) => reactive(data);

      // return initial storage
      return !isWritable ?
          {initialStorage: {item: Object.assign({number: k+1}, item)}, storageCreator} :
          {initialStorage: {number: k+1}, storage: {item: Object.assign(item)}, storageCreator}
    }
  },
  computed: {
    AbStorage() {
      return AbStorage
    },

    /**
     * Return items list
     * @returns {*|*[]}
     */
    items() {
      // Get items list
      const itms = this.getValue(this.block?.properties?.dataSource) || []
      const items = (Array.isArray(itms) ? itms : []).map(el => {
          return typeof el === 'object' ? el : {value: el}
      })

      // Show log message
      this.renderer.getA2U().log(`Repeater items: `, items)

      // Return items
      return items
    },

    /**
     * Repeater data item name
     * @returns {*|string}
     */
    dataFieldName() {
      return this.block?.properties?.data_field_name || 'item'
    },

    /**
     * Determines if virtual scrolling is enabled.
     * @return {boolean} True if virtual scrolling is enabled, false otherwise.
     */
    virtualScroll() {
      return this?.block?.properties?.virtualScrollEnable === 1;
    },

    /**
     * Determines if virtual scrolling is horizontal.
     * @return {boolean} True if virtual scrolling is horizontal, false otherwise.
     */
    virtualScrollHorizontal() {
      return this?.block?.properties?.virtualScrollHorizontal === 1;
    },

    /**
     * Gets the maximum height for virtual scrolling.
     * If virtual scrolling is horizontal, it returns null.
     * @return {number|null} The maximum height for virtual scrolling or null.
     */
    virtualScrollMaxHeight() {
      if (this.virtualScrollHorizontal) {
        return null;
      }

      return this?.block?.properties?.virtualScrollMaxHeight || null;
    },

    /**
     * Gets the item size for virtual scrolling.
     * @return {number} The item size for virtual scrolling. Defaults to 24 if not set.
     */
    virtualScrollItemSize() {
      return this?.block?.properties?.virtualScrollItemSize || 24;
    },

    /**
     * Gets the slice size for virtual scrolling.
     * @return {number} The slice size for virtual scrolling. Defaults to 10 if not set.
     */
    virtualScrollSliceSize() {
      return this?.block?.properties?.virtualScrollSliceSize || 10;
    },

    /**
     * Gets the slice ratio before for virtual scrolling.
     * @return {number} The slice ratio before for virtual scrolling. Defaults to 1 if not set.
     */
    virtualScrollSliceRatioBefore() {
      return this?.block?.properties?.virtualScrollSliceRatioBefore || 1;
    },

    /**
     * Gets the slice ratio after for virtual scrolling.
     * @return {number} The slice ratio after for virtual scrolling. Defaults to 1 if not set.
     */
    virtualScrollSliceRatioAfter() {
      return this?.block?.properties?.virtualScrollSliceRatioAfter || 1;
    },
  }
}

</script>



<style lang="scss">

.repeater-cmp {
  display: flex;
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;

  .repeater-events {
    position: absolute;
    z-index: 1;
  }

  .q-virtual-scroll:not(.q-virtual-scroll--horizontal) .q-virtual-scroll__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  [class*="dg-gutter-x-"] > & {
    &, .q-virtual-scroll__content {
      column-gap: var(--dg-gutter-x, 0px);
    }
  }

  [class*="dg-gutter-y-"] > & {
    &, .q-virtual-scroll__content {
      row-gap: var(--dg-gutter-y, 0px);
    }
  }
}

</style>
