<template>
  <ab-flow-base-cmp :block="block" class="native-component-editor-cmp" :class="classesString" :style="stylesString" :key="componentKey ? componentKey : undefined">
    <component v-if="hasNativeComponent" :is="nativeComponent" ref="nativeComponent" v-bind="componentParameters" v-on="handlersList"/>
    <div v-else class="fake-component">
      <div class="preview" v-if="previewImage" :style="{'background-image': 'url('+previewImage+')'}">

      </div>
      <div v-else class="description">
        {{ description }}
      </div>

      <!--div class="q-mt-md">Params:</div>
      <template v-for="(value, varName) of componentParameters" :key="varName">
        {{ varName }}: {{ value }}
      </template!-->
      <q-separator spaced/>

      <div class="q-mt-md">Possible events:</div>
      <template v-for="(ev, k) of outEvents" :key="k">
        <q-btn :label="ev.name" color="primary" @click="processEvent(ev.name)" class="q-mt-sm"/>
      </template>
    </div>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp},
  inject: {
    /*parentWidget: {
      default: null
    }*/
  },
  props: ['block'],
  name: "NativeComponentEditorCmp",

  created() {
    this.exportIncomingEvents()
  },

  methods: {

    /**
     * Export incoming events
     */
    exportIncomingEvents() {

      // Register events
      for(const ev of this.block.properties?.incoming || []) {

        // Event name
        const evName = ev?.name
        //console.log('Register event', this.block.id, evName)

        // Register event in parent diagram
        this.currentDiagram.registerHandler(this.block.id, evName, (data) => {
          if(this.$refs.nativeComponent?.call) {
            this.$refs.nativeComponent?.call(evName, data)
          } else {
            this.$q.dialog({
              title: 'Warning',
              message: `Native component ${this.name} does not have method ${evName} implemented yet`,
              cancel: false,
              persistent: true
            })
          }
        })
      }
    },

    /**
     * Process event
     * @param ev
     * @param data
     */
    processEvent(ev, data = {}) {
      this.renderer.a2u.logFlowEvent(this, this.block.id, data, ev);

      this.parentDiagram.processOutgoingLinks(this, this.block.id, data, ev)
    }
  },
  computed: {


    /**
     * Check for preview image
     * @return {{}}
     */
    previewImage() {
      // Validate
      return this.renderer.a2u.assetPath(this.block.properties?.previewImage?.source_url);
    },

    /**
     * Return component key
     * @return {(function(): *)|undefined}
     */
    componentKey() {
      return this.interpretString(this.block.properties?.componentKey) || undefined;
    },

    /**
     * Return handlers list
     */
    handlersList() {
      const handlers = {}
      for(const h of this.outEvents) {
        handlers[h.name] = (data) => {
          this.processEvent(h.name, data)
        }
      }

      // Return
      return handlers
    },

    /**
     * Get parameters
     * @return {AudioParamMap | undefined}
     */
    componentParameters() {

      // Init props
      const props = {}

      // Add parameters
      for(const prop of this.block.properties?.parameters || []) {
        props[prop.name] = this.getValue(prop.value)
      }

      // Return
      return props
    },

    /**
     * Check if native component exists
     * @returns {boolean}
     */
    hasNativeComponent() {
      // Validate
      return !!this.nativeComponent
    },

    /**
     * Return native component
     * @returns {*}
     */
    nativeComponent() {
      return this.renderer?.nativeComponents?.[this.name]
    },

    /**
     * Return title
     * @return {{}}
     */
    title() {
      // Validate
      return this.block.properties?.title
    },

    /**
     * Return description
     * @return {{}}
     */
    description() {
      // Validate
      return this.block.properties?.description
    },

    /**
     * Outgoing events
     * @return {{}}
     */
    outEvents() {
      // Validate
      return this.block.properties?.outgoing || []
    },

    /**
     * Return native component name
     * @return {{}}
     */
    name() {
      // Validate
      return this.block.properties?.name
    },

  },
}

</script>

<style lang="scss">

.native-component-editor-cmp {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .fake-component {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .description {

      flex-grow: 1;
      background: repeating-linear-gradient(
              30deg,
              #eee,
              #eee 10px,
              #ddd 10px,
              #ddd 20px
      );
    }

    .preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

</style>
