import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";
import axios from "axios";

/**
 * Code processor
 */
export class ResourcePreloaderProcessor extends BaseComponentProcessor {
    /**
     * Processes the event of resource loading.
     *
     * This method first retrieves the resource from the 'properties' object of the block.
     * If the resource is not found, it logs an error message and calls the '_processError' method.
     * Then, it retrieves the 'source_url' property of the resource or the resource itself.
     * If the 'source_url' property is not found or is not a string or does not start with 'remote:', it logs an error message and calls the '_processError' method.
     * If the 'runMode' property of the 'a2u' object of the 'renderer' object of the 'context' object is 'debug', it calls the '_processProgress' method with the number 100 and the 'assetPath' method of the 'a2u' object of the 'renderer' object of the 'context' object with the 'source_url' property as the argument.
     * It then calls the '_processSuccess' method with the result of the 'assetPath' method as the argument.
     * If the 'runMode' property is not 'debug', it retrieves the 'timeout' property of the 'properties' object of the block or the number 30.
     * It then retrieves the 'CdnManager' plugin from the 'getDevice' method of the 'a2u' object of the 'renderer' object of the 'context' object.
     * It splits the 'source_url' property by the ':' character and retrieves the second element of the resulting array.
     * It then calls the 'load' method of the 'CdnManager' plugin with the second element of the array, the 'timeout' property, and a callback function that calls the '_processProgress' method with the progress as the argument.
     * It then calls the '_processSuccess' method with the result of the 'load' method as the argument.
     * If an error occurs during the execution of the method, it logs an error message and calls the '_processError' method.
     *
     * @async
     * @returns {any} The result of the '_processSuccess' or '_processError' method.
     * @throws {Error} If an error occurs during the execution of the method.
     */
    async processEvent(event, data) {
        try {
            // Get resource
            const resource = this.context.getValue(this.block?.properties?.resource, undefined, data?.input) || null;

            // Check resource
            if (!resource) {
                console.error('Resource not found');

                return this._processError();
            }

            // Get source url
            const sourceUrl = resource?.source_url || resource || null;

            // Download from storage
            if(typeof sourceUrl === 'string' && sourceUrl?.startsWith?.('storage:')) {

                // Load from storage
                const response = await axios.get(this.context.renderer.a2u.assetPath(sourceUrl))

                // Return success
                return this._processSuccess(response.data);
            }

            // Check source url
            if (!sourceUrl || typeof sourceUrl !== 'string' || (!sourceUrl.startsWith('remote:'))) {
                console.error('Invalid resource');

                return this._processError();
            }

            // If debug mode
            if (this.context.renderer.a2u.runMode === 'debug') {
                // Set progress
                this._processProgress(100);

                // Get asset path
                const uri = this.context.renderer.a2u.assetPath(sourceUrl);

                // Return success
                return this._processSuccess(uri instanceof Promise ? await uri : uri);
            }

            // Get timeout
            const timeout = this.context.getValue(this.block?.properties?.timeout) || 30;

            // Get cdn manager
            const cdnManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("CdnManager");

            // Get source
            const [,src] = sourceUrl.split(':') || [];

            // Load resource
            const uri = await cdnManager.load(src, timeout, (progress) => {
                this._processProgress(progress);
            });

            // Return success
            return this._processSuccess(uri);
        } catch (e) {
            console.error('Error processing resource preloader', e);

            return this._processError();
        }
    }

    /**
     * Processes the progress of the resource loading.
     *
     * This method calls the 'processOutgoingLinks' method with the 'context' object, the ID of the block, the progress, and the string 'progress'.
     *
     * @param {number} progress - The progress of the resource loading.
     */
    _processProgress(progress) {
        this.processOutgoingLinks(this.context, this.block.id, progress, 'progress');
    }

    /**
     * Processes the success of the resource loading.
     *
     * This method first sets the value of the 'resultValue' property of the 'properties' object of the block or an empty object to the URL.
     * Then, it calls the 'processOutgoingLinks' method with the 'context' object, the ID of the block, the URL, and the string 'success'.
     * It returns the result of the 'processOutgoingLinks' method.
     *
     * @param {string} url - The URL of the loaded resource.
     * @returns {any} The result of the 'processOutgoingLinks' method.
     */
    _processSuccess(url) {
        this.context.setValue(this.block?.properties?.resultValue || {}, url);

        return this.processOutgoingLinks(this.context, this.block.id, url, 'success');
    }

    /**
     * Processes the error of the resource loading.
     *
     * This method calls the 'processOutgoingLinks' method with the 'context' object, the ID of the block, the boolean false, and the string 'error'.
     * It returns the result of the 'processOutgoingLinks' method.
     *
     * @returns {any} The result of the 'processOutgoingLinks' method.
     */
    _processError() {
        return this.processOutgoingLinks(this.context, this.block.id, false, 'error');
    }
}
