import {BaseToolProcessor} from '../BaseToolProcessor';

/**
 * Code processor
 */
export class ImageExplainerProcessor extends BaseToolProcessor {
    toolName = 'ai:image-explainer';

    /**
     * Process incoming event
     */
    async processEvent() {
        const syncResponse = this.block?.properties?.syncResponse === 1;

        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                ...(!syncResponse ? {'job-id': '1'} : {data: {}}),
                error: {message: `Error calling tool ${this.toolName}`},
            });
        }

        const imageUrl = this.context.getValue(this.block?.properties?.image);
        const image = await this.context.renderer.a2u.assetBase64(imageUrl);
        const instruction = this.context.interpretString(this.block?.properties?.instruction);
        const bindings = this.block?.properties?.instruction?.outgoingBindings || {};

        if (!image || !instruction) {
            this._debugLog('Missing properties: image, instruction');

            return;
        }

        try {
            // Call the tool
            const result = await this.context?.renderer?.a2u?.callTool(this.toolName, {
                image,
                instruction,
            }, { sync: syncResponse });

            // Process the result
            if (syncResponse) {
                // Save the bindings
                if (bindings && Object.keys(bindings).length) {
                    for (const [variable, varToSave] of Object.entries(bindings)) {
                        if (!result[variable]) {
                            continue;
                        }

                        this.context.setOperationValue(varToSave, 'set', {
                            valueType: 'static',
                            value: result[variable],
                        });
                    }
                }

                this.processOutgoingLinks(this.context, this.block.id, result, 'data');
            } else {
                // Process the result
                if (!result?.jobId) {
                    throw 'Job ID not found';
                }

                const varToSaveJobId = this.block.properties?.varToSaveJobId;

                if (varToSaveJobId) {
                    this.context.setOperationValue(varToSaveJobId, 'set', {
                        valueType: 'static',
                        value: result.jobId,
                    });
                }

                this.processOutgoingLinks(this.context, this.block.id, result.jobId, 'job-id');
            }
        } catch (e) {
            console.error(`Error calling tool ${this.toolName}`, e);

            this._debugLog(e);

            this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }
}
