<template>
  <ab-flow-base-cmp :block="block" class="carousel-editor-cmp relative-position" :class="classesString" :style="stylesString">
    <q-carousel
        v-model="currentSlide"
        animated
        :arrows="hasArrows"
        :navigation="hasNavigation"
        :autoplay="slideInterval"
        swipeable
        infinite
        class="full-width full-height"
    >
      <q-carousel-slide v-for="(item, k) of slides" :name="k" class="column col" :key="k">
        <template v-if="isRepeater">
          <data-provider :storage-key="block?.title" :data="new AbStorage(prepareStorage(item, k))">
            <atu-components-renderer :items="block.children"/>
          </data-provider>
        </template>
        <template v-else>
          <fragment-renderer-cmp :block="item"/>
        </template>
      </q-carousel-slide>

    </q-carousel>
  </ab-flow-base-cmp>
</template>

<script>
import {reactive} from 'vue';
import {AbStorage} from 'a2u-renderer-common/src/utils/abStorage';
import {renderMixins} from "../../renderMixins";
import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import FragmentRendererCmp from "../../Containers/Fragment/FragmentRendererCmp.vue";
import AtuComponentsRenderer from "../../../AtuComponentsRenderer";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";

export default {
  components: {AtuComponentsRenderer, DataProvider, FragmentRendererCmp, AbFlowBaseCmp},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  data() {
    return {
      currentSlide: 0
    };
  },
  props: ['block'],
  name: "CarouselEditorCmp",
  computed: {
    AbStorage() {
      return AbStorage
    },

    /**
     * Slide interval
     * @return {number|number}
     */
    slideInterval() {
      return this.block?.properties?.isAutoSlide === 1 ? this.block?.properties?.autoSlideInterval * 1000 : 0
    },

    /**
     * Has arrows
     * @return {boolean}
     */
    hasArrows() {
      return this.block?.properties?.hasArrows === 1 || false
    },

    /**
     * Has navigation
     * @return {boolean}
     */
    hasNavigation() {
      return this.block?.properties?.hasNavigation === 1 || false
    },

    /**
     * Get slides list
     * @return {*}
     */
    slides() {
      if (this.isRepeater) {
        return this.items;
      }

      // Fragments
      const frs = []

      // Get slides list
      const slides = this.renderer.a2u.links.outgoing[this.block.id].children?.map(l => l.target)

      // Get slides
      for(const sl of slides) {
        const fr = this.renderer.a2u.blocks[sl].node
        if(fr) frs.push(fr)
      }

      // Get parent widget
      return frs
    },

    /**
     * Check if repeater
     * @return {boolean}
     */
    isRepeater() {
      return this.block?.properties?.slideSource === 'dataSource';
    },

    /**
     * Return items list
     * @returns {*|*[]}
     */
    items() {
      if (!this.isRepeater) {
        return [];
      }

      // Get items list
      const itms = this.getValue(this.block?.properties?.dataSource) || []
      const items = (Array.isArray(itms) ? itms : []).map(el => {
        return typeof el === 'object' ? el : {value: el}
      })

      // Show log message
      this.renderer.getA2U().log(`Repeater items: `, items)

      // Return items
      return items
    },
  },

  methods: {
    /**
     * Prepare storage
     * @param item
     * @param k
     * @return {{initialStorage: {item: {number: *}}}}
     */
    prepareStorage(item, k) {
      // check if children can change the datasource data
      const storageCreator = (data) => reactive(data);

      // return initial storage
      return {initialStorage: {item: Object.assign({number: k+1}, item)}, storageCreator}
    }
  },
}

</script>

<style lang="scss">

.carousel-editor-cmp {
  .q-carousel__slide {
    padding: 0;
  }

  .q-carousel__navigation-icon, .q-carousel__arrow {
    color: var(--foreground-color-primary);
  }
}

</style>
