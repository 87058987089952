import AtuRenderer from "./renderer/AtuRenderer";

// Import Quasar
import {Quasar} from "quasar"
import quasarUserOptions from "./quasar-user-options";
import DiagramComponentEditorCmp from "./renderer/components/Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import "./renderer/styles.scss";

export default {

    /**
     * Install component
     */
    install(app) {
        // Register Quasar
        app.use(Quasar, quasarUserOptions);

        // Register main component
        app.component('atu-renderer', AtuRenderer);

        // Register main component
        app.component('diagram-component-editor-cmp', DiagramComponentEditorCmp);

    }
}
