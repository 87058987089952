import moment from 'moment';
import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * ChatSendMessageProcessor class for handling chat message sending.
 * Extends the BaseProcessor class.
 */
export class ChatSendMessageProcessor extends BaseComponentProcessor {

  /**
   * Processes the event for the chat bot message.
   * @param {Object} event - The event to process.
   * @param {Object} data - The data for the event.
   * @param input
   * @returns {Promise} The result of the event processing.
   */
  async processEvent(event, data, input) {

    try {
      // Retrieve the channel from the block properties
      const chatId = this.context?.interpretString(this.block?.properties?.channel, data?.input);

      // Throw error if no chatId
      if (!chatId) throw new Error('No chatId');

      // Get chat bot id
      const abChatBotId = this.context?.interpretString(this.block?.properties?.abChatBotId) || this.block?.properties?.abChatBotId;

      // Set the processId
      const message = (this.context?.interpretString(this.block?.properties?.message) || this.block?.properties?.message)?.trim?.();

      // Get widget id
      const widgetId = this.block?.properties?.widgetComponentId;

      // Get message direction
      const direction = this.block?.properties?.direction || "outgoing";

      // Get chat action
      const sendAction = this.block?.properties?.sendAction || "message";

      // Retrieve the database
      const db = this.context.renderer?.a2u?.dbs[this.block?.properties?.dbId];

      // Retrieve the tableId and the fields of the table
      const tableId = this.block?.properties?.tableId;

      // Set the dbModel property to the model of the table in the database
      const dbModel = db?.models[tableId];

      // Check dbModel
      if (!dbModel) throw new Error('No dbModel');

      // Init metadata
      const metadata = {};

      // Add metadata if message contains widget
      if (widgetId) {

        // Set widgetId
        metadata.widgetId = widgetId;

        // Store widget args
        metadata.widgetArgs = {}

        // Prepare params if set
        for (const [name, val] of Object.entries(this.block?.properties?.widgetArgs || {})) {
          // Set param
          metadata.widgetArgs[name] = this.context?.getValue(val, undefined, input)
        }
      }

      // Command send action
      if (sendAction === "command") {
        switch (this.block?.properties?.sendCommand) {

            // Delete chat
          case "delete-chat":
            // Send message to remote server
            try {
              // Delete remote chat
              await this.app.renderer.app.client.call(this.app.renderer.a2u.getBackendUrl() + "/ab-chat", "deleteChat", abChatBotId, chatId);

              // Delete local history
              await this.clearHistory(dbModel, chatId);

            } catch (ex) {
              throw new Error(`Can't delete chat: ${chatId}`);
            }
            break;

            // Clear history
          case "clear-history":
            await this.clearHistory(dbModel, chatId);
            break;

          default:
            throw new Error('Unknown command');
        }
      }

      // Regular send message action
      if (sendAction === "message") {

        // Send message to local chat db
        if (this.block.properties?.sendLocally) {

          // Save to local db
          await dbModel.save({
            chat_id: chatId,
            type: direction,
            message: message,
            //options: [],
            //option: e,
            metadata,
            sent_at: moment().unix()
          });
        }

        // Send message to remote server
        if (this.block.properties?.sendToServer) {
          // Get chat arguments
          const chatArgs = this.context.getArguments(this.block.id, undefined, this.block.properties.chatArgs || {});

          // Send message to remote server
          const snRes = await this.app.renderer.app.client.call(this.app.renderer.a2u.getBackendUrl() + "/ab-chat", "sendMessage", abChatBotId, chatId, {
            type: "text",
            content: message
          }, chatArgs);
          if (snRes !== true) {
            throw new Error(snRes);
          }
        }
      }

      // Success event
      return this.app.processOutgoingLinks(this.context, this.block.id, data?.input || {}, 'success');
    } catch (ex) {
      // Log error
      console.error('Error in ChatSendMessageProcessor:', ex.message);

      // Error event
      return this.app.processOutgoingLinks(this.context, this.block.id, data?.input || {}, 'error');
    }
  }

  /**
   * Clear local chat history.
   * @param chatId
   * @return {Promise<void>}
   */
  async clearHistory(dbModel, chatId) {
    await dbModel.query().getRaw({
      query: `DELETE
              FROM ${dbModel.entity}
              WHERE chat_id = ?`,
      params: [chatId],
    });

    // Trigger update
    dbModel.triggerUpdate();
  }
}
