<template>

  <ab-flow-base-cmp class="upload-editor-cmp" :block="block" :transparent="true" :class="classesString" :style="stylesString">
    <q-file
      ref="uploader"
      :key="upKey"
      class="hidden"
      v-model="fileValue"
      :accept="acceptTypes"
      @update:model-value="onPickFile"
      v-bind="dynamicProps"
    />
    <q-field v-if="!currentValue?.title" :hint="inputHint" :label="inputLabel" class="full-width" v-bind="dynamicProps">
      <div @click.stop.prevent="pickFile" class="full-width"></div>
      <template v-slot:append>
        <q-btn icon="upload"  round size="sm" outline @click="pickFile"/>
      </template>
    </q-field>
    <q-input v-else :label="inputLabel" :hint="inputHint" class="full-width" readonly :model-value="currentValue.title" v-bind="dynamicProps">
      <template v-slot:append>
        <q-btn icon="clear" outline round size="sm" @click="currentValue={};upKey++"/>
      </template>
    </q-input>

    <q-dialog
      v-if="useCropper"
      v-model="cropper.dialog"
    >
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Crop image</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <vue-picture-cropper
            class="upload-editor-cmp__cropper"
            :boxStyle="{
              width: '100%',
              height: '100%',
              backgroundColor: '#f8f8f8',
              margin: 'auto',
            }"
            :img="cropper.image"
            :options="{
              viewMode: 1,
              dragMode: 'none',
              aspectRatio: imageDimensions.width / imageDimensions.height,
              cropBoxResizable: false,
            }"
            :presetMode="{
              mode: 'fixedSize',
              width: imageDimensions.width,
              height: imageDimensions.height,
            }"
          />
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn flat label="Crop" color="primary" @click="applyCropperResult" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </ab-flow-base-cmp>

</template>

<script>
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../../components/Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "../../components/Form/formComponentsMixins";
import {nanoid} from "nanoid";

export default {
  components: {AbFlowBaseCmp, VuePictureCropper},
  mixins: [renderMixins, formComponentsMixins],
  props: ['block'],
  name: "FileUploaderEditorCmp",
  data: () => ({
    fileValue: [],
    upKey: 0,
    uploadUrl: false,
    cropper: {
      dialog: false,
      image: null,
    },
  }),

  created() {
    // Get upload url
    this.uploadUrl = this.block.properties?.moduleId ? this.renderer.a2u.source.modules[this.block.properties?.moduleId].endpointUrl : false
  },

  methods: {

    /**
     * Pick file
     */
    pickFile() {
      this.$refs.uploader.pickFiles()
    },

    /**
     * Handles the file selection event.
     * If the useCropper property is false, it directly uploads the file.
     * Otherwise, it converts the file to a dataURL and passes it to the cropper component.
     * @param {File} file - The selected file.
     */
    async onPickFile(file) {
      if (!this.useCropper) {
        await this.onUpload(file);

        return;
      }

      // Convert to dataURL and pass to the cropper component
      const reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Update the picture source of the `img` prop
        this.cropper.image = String(reader.result)

        // Show the modal
        this.cropper.dialog = true;
      };
    },

    /**
     * Applies the result of the cropping operation.
     * If the cropper is not defined, it returns immediately.
     * Otherwise, it retrieves the cropped file from the cropper, hides the dialog, uploads the file, and resets the image.
     */
    async applyCropperResult() {
      if (!cropper) {
        await this.onUpload(this.cropper.image);

        return;
      }

      const file = await cropper.getFile({
        fileName: this.cropper.image.name,
      });

      this.cropper.dialog = false;

      await this.onUpload(file);

      this.cropper.image = null;
    },

    /**
     * On upload
     */
    async onUpload(file) {
      // Increment key
      this.upKey++

      // Check for upload mode
      if(this.storageUpload) {

        // Show loading
        this.$q.loading.show()

        try {
          // Upload file and get path
          const res = await this.app.client.upload(`images/${nanoid(10)}.png`, file, this.uploadUrl)

          // Set current value
          this.currentValue = {
            type: "file",
            moduleId: this.block.properties?.moduleId,
            storage_module_id: this.block.properties?.moduleId,
            source_url: 'storage:'+res,
            title: file.name
          }
        } catch (e) {
          this.$q.dialog({
            title: 'Error',
            message: 'Error uploading file: ' + e.message,
            ok: 'Ok'
          })
        }

        // Show loading
        this.$q.loading.hide()

      }
    }
  },

  computed: {

    /**
     * is autogrow
     * @return {*}
     */
    storageUpload() {
      return this.block.properties?.storageUpload ? true : false
    },

    /**
     * input label
     * @return {*|string}
     */
    inputLabel() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * input hint
     * @return {*|string}
     */
    inputHint() {
      return this.interpretString(this.block.properties?.hint);
    },

    /**
     * Determines the accepted file types for the file uploader.
     * If the fileType property of the block is set to 'image', it will accept only image files.
     * Otherwise, it will accept any file type.
     * @return {string|null} The accepted file types for the file uploader.
     */
    acceptTypes() {
      return this.block.properties?.fileType === 'image' ? 'image/*' : undefined;
    },

    /**
     * Calculates the dimensions for the image to be uploaded.
     * The width and height are retrieved from the block's properties.
     * If no dimensions are specified, it defaults to 0.
     * @return {Object} An object containing the width and height of the image.
     */
    imageDimensions() {
      return {
        width: this.block.properties?.imageWidth || 0,
        height: this.block.properties?.imageHeight || 0,
      };
    },

    /**
     * Determines whether the image cropper should be used.
     * The cropper is used if the fileType property of the block is set to 'image',
     * the imageFixedDimensions property is set to 1, and both width and height are specified.
     * @return {boolean} True if the image cropper should be used, false otherwise.
     */
    useCropper() {
      return this.block.properties?.fileType === 'image'
        && this.block.properties?.imageFixedDimensions === 1
        && this.imageDimensions.width
        && this.imageDimensions.height;
    },
  }
}

</script>

<style lang="scss">

.upload-editor-cmp {
  position: relative;
  .links-wrapper {
    right: 0;
    left: auto;
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    .q-field__control {
      color: var(--control-focus-color);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }
    }
  }

  &__cropper {
    max-height: 80vh;
  }
}

</style>
