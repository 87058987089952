import {BaseComponentProcessor} from "../../../utils/processor/BaseComponentProcessor";
import VoiceChatScreen from "./VoiceChatScreen.vue";

/**
 * VoiceChatProcessor class for handling voice chat.
 */
export class VoiceChatProcessor extends BaseComponentProcessor {

    /**
     * Processes the event for the voice chat.
     * @return {Promise<void>}
     */
    async processEvent(event, data) {

        // Get voice chat params
        const channel = this.context.interpretString(this.block.properties.channel, data?.input);
        const abChatBotId = this.context.interpretString(this.block.properties.abChatBotId, data?.input);
        const title = this.context.interpretString(this.block.properties.title, data?.input);
        const backgroundImage = this.context.getValue(this.block.properties.backgroundImage, data?.input);
        const botGender = this.context.getValue(this.block.properties.botGender, data?.input);

        // Show dialog
        this.context.$q.dialog({
            // Call screen component
            component: VoiceChatScreen,

            // You can pass props to the component
            componentProps: {
                channel,
                title,
                abChatBotId,
                backgroundImage,
                renderer: this.context.renderer,
                caller: this,
                botGender
            },
            onDismiss: () => {
                console.log('Dialog dismissed');
            },
        });
    }
}
