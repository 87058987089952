<template>
  <atu-renderer
    v-if="source"
    :db-config="db"
    :functions="methodsList"
    :run-mode="runMode"
    :db-server-url="dbServerUrl"
    :startEvent="startEvent"
    :source="source"
    :storage="storage"
    :native-components="componentsList"
    platform="web"
    :module-id="moduleId"
    :plugins="plugins"
    :debug="10"
    :designer-components-list="designerComponentsList"
  />
</template>

<script>
import {markRaw} from 'vue';
import { decode as msgpackDecode } from '@msgpack/msgpack';
import AtuRenderer from "@/renderer/AtuRenderer";
import {componentsList} from "./componentsList";
import db from "./db";
import {methodsList} from "./methodsList";
import "@/../../common/assets/fonts/fonts.css";
import {ThreePlugin} from './ThreePlugin';
import {designerComponentsList} from '@/renderer/components/designerComponentsList';

export default {
  name: 'TestApp',

  setup() {
    return {
      designerComponentsList,
    }
  },

  computed: {
    methodsList() {
      return methodsList
    },
    db() {
      return db
    },
    componentsList() {
      return componentsList
    }
  },
  components: {
    AtuRenderer
  },
  data: () => ({
    source: false,
    moduleId: false,
    storage: false,
    startEvent: "start",
    runMode: "debug",
    dbServerUrl: "",
    plugins: {
      ThreePlugin: ThreePlugin,
    },
  }),
  async created() {

    // Get segments
    const [, mId, mode] = window.location.pathname.split('/')

    // Reset data if mode is reset
    if (mode === "reset") {
      localStorage.clear()
      sessionStorage.clear()

      // Reload page with "debug" mode
      location.href = `/${mId}/debug`
    }

    // Store run mode
    this.runMode = mode || "debug"

    // Set db url
    this.dbServerUrl = process.env.VUE_APP_DB_URL

    // Get appId from hash
    this.moduleId = parseInt(mId);

    // Start event
    this.startEvent = "start"

    // Load source from server
    if (this.moduleId) this.app.client.call('app', 'source', this.moduleId, 'debug', false).then(async (appSource) => {
      // Get source
      this.source = markRaw(appSource?.data ? msgpackDecode(new Uint8Array(appSource?.data)) : {});
    })
  },
}
</script>
